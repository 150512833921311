import React from 'react';
import { Helmet } from 'react-helmet';

import { COLOR_COUNT_0, COLOR_RANK_0 } from '../colors';
import { formatCountValue } from '../utils';

import { CityStackedColumnChart, MicroBarChart, MicroLineChart } from '../components/charts';
import { Row333333 } from '../components/grid';
import { CountMetric, RankMetric } from '../components/metrics';
import { Paper, PaperLink, PaperPadding, PaperTitle } from '../components/papers';
import { Ranking, RankingLink, RankingTable } from '../components/rankings';
import { AppLayout } from '../layout';

/**
 *
 */
export function City(props) {
  const { appData, categories, charts, currentRankingSegment, details, metrics } = props.pageContext;

  return (
    <AppLayout data={appData} title={`Tech Trends in ${details.name_display}, ${details.state.name_display}`}>
      <Helmet>
        <title>
          {details.name_display}, {details.state.name_display} | Cities | StackTrends
        </title>
        <meta name="description" content="" />
      </Helmet>

      <Row333333>
        <RankMetric
          value={`Rank ${
            metrics.currentItem?.rank.toLocaleString() ?? '-'
          } of ${metrics.currentListItemCount.toLocaleString()}`}
          label="Current"
        />

        <CountMetric value={`${formatCountValue(metrics.currentItem.count)} Listings`} label="Current" />

        <CountMetric value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`} label="Historic" />
      </Row333333>

      <Paper>
        <PaperTitle>Number of Jobs Over Time</PaperTitle>

        <PaperPadding>
          <MicroBarChart
            chartId="micro_bar_chart_city_listing_count"
            color={COLOR_COUNT_0}
            data={charts.microBarListingCount}
          />
        </PaperPadding>
      </Paper>

      <Paper>
        <PaperTitle>Job Rank in Cities</PaperTitle>

        <PaperPadding>
          <MicroLineChart
            chartId="micro_line_chart_city_rank"
            color={COLOR_RANK_0}
            data={charts.microLineRank}
            inverse={true}
          />
        </PaperPadding>

        <Ranking>
          <RankingTable data={currentRankingSegment} parentPath="cities" />
          <RankingLink to="/cities/">View All City Results</RankingLink>
        </Ranking>
      </Paper>

      {categories.map((category) => (
        <Paper key={category.details.id}>
          <PaperTitle>
            Most In-Demand {category.details.name_display} in {details.name_display}
          </PaperTitle>

          <CityStackedColumnChart
            chartId={`city_technology_stacked_column_chart_${category.details.id}`}
            data={category.charts.stackedBarCityTechnology}
            nameDisplayCategory={category.details.name_display}
            nameDisplayCity={details.name_display}
            nameDisplayState={details.state.name_display}
          />

          <PaperLink to={`/technologies/${category.details.name}/`}>View National Ranking</PaperLink>
        </Paper>
      ))}
    </AppLayout>
  );
}

export default City;
